<!-- <button
  (click)="openConfig.emit()"
  class="config-panel-toggle"
  color="primary"
  mat-fab
  type="button">
  <mat-icon svgIcon="mat:settings"></mat-icon>
</button> -->



<button (click)="scrollToTop()" class="config-panel-toggle" color="primary" mat-fab type="button">
    <mat-icon svgIcon="mat:arrow_upward"></mat-icon>
</button>