<div (keyup.escape)="close()" [class.show]="show$ | async" class="search">
    <button (click)="close()" class="ltr:right-12 rtl:left-12 top-12 absolute" color="primary" mat-icon-button
        type="button">
        <mat-icon svgIcon="mat:close"></mat-icon>
    </button>

    <input #searchInput (keyup.enter)="search()" [formControl]="searchCtrl" class="search-input"
        placeholder="{{listFormItemNm[2]}}" />
    <div class="search-hint">{{listFormItemNm[1]}}</div>
</div>

<div (click)="close()" *ngIf="show$ | async" class="search-overlay"></div>