<div (mouseenter)="collapseOpenSidenav()" (mouseleave)="collapseCloseSidenav()" [class.collapsed]="collapsed"
    [class.open]="collapsed && (collapsedOpen$ | async)" class="vex-sidenav flex flex-col">
    <div class="vex-sidenav-toolbar__container">
        <div class="sidenav-toolbar flex flex-col items-center justify-center">
            <img [src]="imageUrl$ | async" alt="Logo" class="w-8 select-none mb-2" />
            <p class="vex-sidenav-toolbar__headline text-blue-700 font-sm text-center">
                {{ title$ | async }}
            </p>
            <button (click)="toggleCollapse()" *ngIf="showCollapsePin$ | async"
                class="vex-sidenav-toolbar__collapse-toggle mt-2 leading-none hidden lg:block" mat-icon-button
                type="button">
                <mat-icon *ngIf="!collapsed" class="icon-xs" svgIcon="mat:radio_button_checked"></mat-icon>
                <mat-icon *ngIf="collapsed" class="icon-xs" svgIcon="mat:radio_button_unchecked"></mat-icon>
            </button>
        </div>

        <div *ngIf="searchVisible$ | async" class="vex-sidenav-search__container">
            <div (click)="openSearch()" class="vex-sidenav-search relative" matRipple
                matRippleColor="var(--vex-sidenav-item-ripple-color)">
                <mat-icon class="flex-none vex-sidenav-search__icon" svgIcon="mat:search"></mat-icon>
                <div class="flex-auto vex-sidenav-search__placeholder">
                    Quick Search
                </div>
                <div class="flex-none vex-sidenav-search__keybind">Ctrl K</div>
            </div>
        </div>

        <div class="vex-sidenav-toolbar__divider"></div>
    </div>

    <vex-scrollbar class="flex-1 flex flex-col">
        <div class="pt-6 flex-1">
            <vex-sidenav-item *ngFor="let item of items$ | async; trackBy: trackByRoute" [item]="item"
                [level]="0"></vex-sidenav-item>
        </div>

        <div class="vex-sidenav-footer flex items-center justify-center opacity-20 px-6 py-6 select-none flex-none">
            <img [src]="imageUrl$ | async" alt="Logo" class="w-8 select-none" />
        </div>
    </vex-scrollbar>

    <div *ngIf="userVisible$ | async" class="vex-sidenav-user__container flex-none">
        <div #userProfileMenuOriginRef (click)="openProfileMenu(userProfileMenuOriginRef)"
            [class.vex-sidenav-user--open]="userMenuOpen$ | async" class="vex-sidenav-user flex items-center" matRipple
            matRippleColor="var(--vex-sidenav-item-ripple-color)">
            <img alt="User Avatar" class="vex-sidenav-user__image flex-none"
                src="../../../../assets/img/avatars/1.jpg" />
            <div class="vex-sidenav-user__content flex-auto">
                <div class="vex-sidenav-user__title">David Smith</div>
                <div class="vex-sidenav-user__subtitle">Product Manager</div>
            </div>
            <mat-icon class="vex-sidenav-user__dropdown-icon flex-none" svgIcon="mat:arrow_drop_down"></mat-icon>
        </div>
    </div>
</div>