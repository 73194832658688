import { Injectable } from '@angular/core';
import { IObjectString } from '../app.interface';
import { NavigationLoaderService } from '../core/navigation/navigation-loader.service';
import { NavigationItem } from '../core/navigation/navigation-item.interface';
import * as Const from '../app.const';
import { WebServiceService } from './web-service.service';
import { AccessInfoService } from './access-info.service';
import { catchError, map, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppFunctionService {

    constructor(
        private navigationLoaderService: NavigationLoaderService,
        private webService: WebServiceService,
        private readonly accessInfo: AccessInfoService
    ) {
    }

    reloadListFunction(language: string): void {
        this.getListFunction(language).subscribe({
            next: (listFunctions: any) => {
                if (listFunctions) {
                    this.navigationLoaderService.items = [...listFunctions];
                } else {
                    console.error('Không có dữ liệu nào được trả về từ API.');
                }
            },
            error: (error: any) => {
                console.error('Đã xảy ra lỗi khi tải danh sách:', error);
            },
        });
    }

    getListFunction(language: string): any {
        const request = {
            accessInfo: this.accessInfo.getAll()
        };

        return this.webService.callWs('getFunctions', request).pipe(
            map((data: any) => {
                if (data && data.listFunctions) {
                    return data.listFunctions;
                } else {
                    console.error('Không có dữ liệu hoặc xảy ra lỗi.');
                    return [];
                }
            }),
            catchError((error) => {
                console.error('Lỗi xảy ra:', error);
                return of([]); // Trả về một danh sách rỗng nếu có lỗi
            })
        );
    }

    getListFormItemNm(screenId: string) {
        const request = {
            accessInfo: this.accessInfo.getAll(),
            SCREEN_ID: screenId
        };

        return this.webService.callWs('getFormItemNm', request).pipe(
            map((data: any) => {
                if (data && data.listFormItemNm) {
                    var result: IObjectString = {};
                    for (let item of data.listFormItemNm) {
                        result[item.FORM_ITEM_ID] = item.FORM_ITEM_NM;
                    }
                    return result;
                } else {
                    console.error('Không có dữ liệu hoặc xảy ra lỗi.');
                    return [];
                }
            }),
            catchError((error) => {
                console.error('Lỗi xảy ra:', error);
                return of([]); // Trả về một danh sách rỗng nếu có lỗi
            })
        );
    }
}
