<vex-base-layout *ngIf="config$ | async as config">
    <vex-progress-bar></vex-progress-bar>

    <vex-search></vex-search>

    <mat-sidenav-container class="vex-layout-sidenav-container">
        <mat-sidenav (closedStart)="onSidenavClosed()" [disableClose]="!!(sidenavDisableClose$ | async)"
            [fixedInViewport]="!!(sidenavFixedInViewport$ | async)" [mode]="(sidenavMode$ | async) ?? 'side'"
            [opened]="!!(sidenavOpen$ | async)">
            <vex-sidenav [collapsed]="!!(sidenavCollapsed$ | async)"></vex-sidenav>
        </mat-sidenav>

        <mat-sidenav (closedStart)="onQuickpanelClosed()" [opened]="!!(quickpanelOpen$ | async)"
            class="vex-layout-quickpanel" mode="over" position="end">
            <vex-quickpanel></vex-quickpanel>
        </mat-sidenav>

        <mat-sidenav-content class="vex-layout-sidenav-content">
            <!-- <vex-toolbar [class.dark]="config.layout === 'vertical'" class="vex-toolbar"></vex-toolbar> -->
            <vex-toolbar class="vex-toolbar"></vex-toolbar>

            <main class="vex-layout-content">
                <router-outlet></router-outlet>
            </main>

            <div class="bg-gray-100 py-20">
                <div class="max-w-6xl mx-auto grid grid-cols-1 sm:grid-cols-3 gap-6 px-4">
                    <!-- Card 1 -->
                    <div (click)="linkToContact()"
                        class="cursor-pointer group bg-white rounded-lg shadow-md overflow-hidden transform transition duration-300 hover:shadow-xl hover:scale-105">
                        <div class="relative overflow-hidden">
                            <img src="./assets/img/topfooter/topfooter_01.jpg" alt="Contact Us"
                                class="w-full h-48 object-cover transition-transform duration-500 group-hover:scale-110 group-hover:-translate-y-2" />
                        </div>
                        <div class="p-4 transition-colors duration-300 hover:bg-blue-100">
                            <h3 class="text-lg font-bold text-gray-800 group-hover:text-blue-500"
                                [innerHTML]="listFormItemNm[1]"></h3>
                            <p class="text-sm text-gray-600" [innerHTML]="listFormItemNm[2]"></p>
                        </div>
                    </div>
                    <!-- Card 2 -->
                    <div (click)="linkToNews()"
                        class="cursor-pointer group bg-white rounded-lg shadow-md overflow-hidden transform transition duration-300 hover:shadow-xl hover:scale-105">
                        <div class="relative overflow-hidden">
                            <img src="./assets/img/topfooter/topfooter_02.jpg" alt="Announcements"
                                class="w-full h-48 object-cover transition-transform duration-500 group-hover:scale-110 group-hover:translate-x-2" />
                        </div>
                        <div class="p-4 transition-colors duration-300 hover:bg-blue-100">
                            <h3 class="text-lg font-bold text-gray-800 group-hover:text-blue-500"
                                [innerHTML]="listFormItemNm[3]"></h3>
                            <p class="text-sm text-gray-600" [innerHTML]="listFormItemNm[4]"></p>
                        </div>
                    </div>
                    <!-- Card 3 -->
                    <div (click)="linkToAccessMap()"
                        class="cursor-pointer group bg-white rounded-lg shadow-md overflow-hidden transform transition duration-300 hover:shadow-xl hover:scale-105">
                        <div class="relative overflow-hidden">
                            <img src="./assets/img/topfooter/topfooter_03.jpg" alt="Access Map"
                                class="w-full h-48 object-cover transition-transform duration-500 group-hover:scale-110 group-hover:translate-y-2" />
                        </div>
                        <div class="p-4 transition-colors duration-300 hover:bg-blue-100">
                            <h3 class="text-lg font-bold text-gray-800 group-hover:text-blue-500"
                                [innerHTML]="listFormItemNm[5]"></h3>
                            <p class="text-sm text-gray-600" [innerHTML]="listFormItemNm[6]"></p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Footer -->
            <footer class="bg-blue-700 text-white py-6 ">
                <div class="max-w-6xl mx-auto px-4 text-center">
                    <div class="flex flex-col md:flex-row justify-center items-center gap-4">
                        <img src="favicon.ico" alt="Logo" class="h-10" />

                        <p class="text-sm md:text-base" [innerHTML]="listFormItemNm[7]">
                        </p>
                    </div>
                </div>
            </footer>

            <vex-footer *ngIf="config.footer.visible" class="vex-footer"></vex-footer>
        </mat-sidenav-content>
    </mat-sidenav-container>

    <vex-config-panel-toggle (openConfig)="configpanel.open()"></vex-config-panel-toggle>

    <!-- CONFIGPANEL -->
    <vex-sidebar #configpanel [invisibleBackdrop]="true" [opened]="!!(configPanelOpen$ | async)" position="right">
        <vex-config-panel></vex-config-panel>
    </vex-sidebar>
    <!-- END CONFIGPANEL -->
</vex-base-layout>