<div class="min-h-screen bg-gray-50" @scaleFadeIn *ngIf="formItemNmLoaded">
    <!-- Header -->
    <header class="relative bg-gradient-to-br from-blue-600 via-blue-500 to-blue-400 text-white overflow-hidden">
        <div class="absolute inset-0 bg-cover bg-center opacity-30"
            style="background-image: url('./assets/img/bussiness/bussiness-header.jpg');"></div>
        <div class="relative z-10 text-center py-20 px-4">
            <h1 class="text-6xl mb-10 font-extrabold tracking-wide drop-shadow-lg" [innerHTML]="listFormItemNm[1]"></h1>
        </div>
        <div class="absolute bottom-0 left-0 w-full h-16 bg-white rounded-t-[50%]"></div>
    </header>

    <!-- Main Content -->
    <main class="container mx-auto py-12">
        <!-- Section 1: Overview Table -->
        <section class="mb-16">
            <div class="overflow-hidden rounded-lg shadow-lg bg-white p-8 mb-16">
                <h2 class="text-4xl font-bold text-blue-700 mb-8 text-center" [innerHTML]="listFormItemNm[2]"></h2>
                <table class="p-6 min-w-full border-collapse table-auto">
                    <tbody class="divide-y divide-gray-200">
                        <tr>
                            <th class="px-6 w-40 py-4 bg-gray-50 text-left text-sm font-bold text-gray-600"
                                [innerHTML]="listFormItemNm[3]">
                            </th>
                            <td class="px-6 py-4 text-sm text-gray-800">グローバル人財事業協同組合（GLOBAL HUMAN RESOURCCES BUSINESS
                                COOPERATIVE　通称：GHBC）</td>
                        </tr>
                        <tr>
                            <th class="px-6 py-4 bg-gray-50 text-left text-sm font-bold text-gray-600"
                                [innerHTML]="listFormItemNm[4]"></th>
                            <td class="px-6 py-4 text-sm text-gray-800">
                                本部：〒116-0013　東京都荒川区西日暮里２丁目５４番２号　弥栄ビル502号室<br>
                                TEL：03-6806-6238　FAX：03-6806-7549<br>
                                福岡事務所：〒812-0007　福岡市博多区東比恵2丁目17―23　ローズマンション第一博多　1号館　311号室<br>
                                TEL：092-402-1741　FAX：092-402-1742
                            </td>
                        </tr>
                        <tr>
                            <th class="px-6 py-4 bg-gray-50 text-left text-sm font-bold text-gray-600"
                                [innerHTML]="listFormItemNm[5]"></th>
                            <td class="px-6 py-4 text-sm text-gray-800">平成29年2月1日</td>
                        </tr>
                        <tr>
                            <th class="px-6 py-4 bg-gray-50 text-left text-sm font-bold text-gray-600"
                                [innerHTML]="listFormItemNm[6]"></th>
                            <td class="px-6 py-4 text-sm text-gray-800">707万円</td>
                        </tr>
                        <tr>
                            <th class="px-6 py-4 bg-gray-50 text-left text-sm font-bold text-gray-600"
                                [innerHTML]="listFormItemNm[7]"></th>
                            <td class="px-6 py-4 text-sm text-gray-800">代表理事：加藤　富夫</td>
                        </tr>
                        <tr>
                            <th class="px-6 py-4 bg-gray-50 text-left text-sm font-bold text-gray-600"
                                [innerHTML]="listFormItemNm[8]"></th>
                            <td class="px-6 py-4 text-sm text-gray-800">
                                <ol class="list-decimal list-inside">
                                    <li [innerHTML]="listFormItemNm[9]"></li>
                                    <li [innerHTML]="listFormItemNm[10]"></li>
                                    <li [innerHTML]="listFormItemNm[11]"></li>
                                    <li [innerHTML]="listFormItemNm[12]"></li>
                                    <li [innerHTML]="listFormItemNm[13]"></li>
                                    <li [innerHTML]="listFormItemNm[14]"></li>
                                </ol>
                            </td>
                        </tr>
                        <tr>
                            <th class="px-6 py-4 bg-gray-50 text-left text-sm font-bold text-gray-600"
                                [innerHTML]="listFormItemNm[15]"></th>
                            <td class="px-6 py-4 text-sm text-gray-800">
                                北海道・福島県・栃木県・茨城県・群馬県・埼玉県・東京都・千葉県・神奈川県・長野県・新潟県・福井県・静岡県・三重県・滋賀県・大阪府・福岡県・熊本県・鹿児島県
                            </td>
                        </tr>
                        <tr>
                            <th class="px-6 py-4 bg-gray-50 text-left text-sm font-bold text-gray-600"
                                [innerHTML]="listFormItemNm[16]"></th>
                            <td class="px-6 py-4 text-sm text-gray-800">みずほ銀行
                                <br>
                                静岡銀行
                            </td>
                        </tr>
                        <tr>
                            <th class="px-6 py-4 bg-gray-50 text-left text-sm font-bold text-gray-600"
                                [innerHTML]="listFormItemNm[17]">
                            </th>
                            <td class="px-6 py-4 text-sm text-gray-800">
                                ３３社
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>

        <!-- Section 2: Certificates -->
        <section class="bg-white shadow-lg rounded-xl p-8 mb-16">
            <!-- Tiêu đề -->
            <h2 class="text-4xl font-bold text-blue-700 mb-8 text-center" [innerHTML]="listFormItemNm[18]"></h2>

            <!-- Nội dung -->
            <div class="p-6">
                <p class="text-gray-700 leading-relaxed mb-6" [innerHTML]="listFormItemNm[19]">

                </p>

                <!-- Hình ảnh -->
                <div class="grid grid-cols-1 md:grid-cols-2 gap-6 justify-center">
                    <!-- Hình ảnh 1 -->
                    <div class="flex flex-col items-center hover:scale-105 transition duration-300">
                        <img src="./assets/img/license/license-01.png" alt="監理団体許可証"
                            class="max-h-full max-w-full object-contain">
                        <p class="text-center text-gray-600 mt-4" [innerHTML]="listFormItemNm[20]"></p>
                    </div>

                    <!-- Hình ảnh 2 -->
                    <div class="flex flex-col items-center hover:scale-105 transition duration-300">
                        <img src="./assets/img/license/license-02.png" alt="登録支援機関認定証"
                            class="max-h-full max-w-full object-contain">
                        <p class="text-center text-gray-600 mt-4" [innerHTML]="listFormItemNm[21]"></p>
                    </div>
                </div>
            </div>
        </section>

        <!-- Section 3: 登録支援機関の詳細 -->
        <section class="bg-white shadow-lg rounded-xl p-8 mb-16">
            <!-- Tiêu đề -->
            <h2 class="text-4xl font-bold text-blue-700 mb-8 text-center" [innerHTML]="listFormItemNm[22]"></h2>

            <!-- Nội dung -->
            <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                <!-- Thông tin chi tiết -->
                <div class="text-gray-700 leading-relaxed space-y-6">
                    <p [innerHTML]="listFormItemNm[23]">

                    </p>
                    <ul class="space-y-4">
                        <!-- Mục 1 -->
                        <li class="flex items-center">
                            <span
                                class="inline-block bg-blue-500 text-white text-sm font-bold py-1 px-3 rounded-full mr-4">
                                １
                            </span>
                            <span class="flex-1">
                                <span class="font-bold" [innerHTML]="listFormItemNm[24]"></span> 19登-001192
                            </span>
                        </li>

                        <!-- Mục 2 -->
                        <li class="flex items-center">
                            <span
                                class="inline-block bg-blue-500 text-white text-sm font-bold py-1 px-3 rounded-full mr-4">
                                ２
                            </span>
                            <span class="flex-1">
                                <span class="font-bold" [innerHTML]="listFormItemNm[25]"></span> 2019年7月11日
                            </span>
                        </li>

                        <!-- Mục 3 -->
                        <li class="flex items-center">
                            <span
                                class="inline-block bg-blue-500 text-white text-sm font-bold py-1 px-3 rounded-full mr-4">
                                ３
                            </span>
                            <span class="flex-1">
                                <span class="font-bold" [innerHTML]="listFormItemNm[26]"></span> グローバル人財事業協同組合
                            </span>
                        </li>

                        <!-- Mục 4 -->
                        <li class="flex items-center">
                            <span
                                class="inline-block bg-blue-500 text-white text-sm font-bold py-1 px-3 rounded-full mr-4">
                                ４
                            </span>
                            <span class="flex-1">
                                <span class="font-bold" [innerHTML]="listFormItemNm[27]"></span>
                                〒116-0013　東京都荒川区西日暮里２丁目５４番２号　弥栄ビル502号室
                            </span>
                        </li>

                        <!-- Mục 5 -->
                        <li class="flex items-center">
                            <span
                                class="inline-block bg-blue-500 text-white text-sm font-bold py-1 px-3 rounded-full mr-4">
                                ５
                            </span>
                            <span class="flex-1">
                                <span class="font-bold" [innerHTML]="listFormItemNm[28]"></span> 加藤　富夫
                            </span>
                        </li>

                        <!-- Mục 6 -->
                        <li class="flex items-center">
                            <span
                                class="inline-block bg-blue-500 text-white text-sm font-bold py-1 px-3 rounded-full mr-4">
                                ６
                            </span>
                            <span class="flex-1">
                                <span class="font-bold" [innerHTML]="listFormItemNm[29]"></span> 2024年7月11日から2029年7月10日
                            </span>
                        </li>
                    </ul>
                </div>

                <!-- Hình ảnh -->
                <div
                    class="shadow-lg flex justify-center items-center flex flex-col items-center hover:scale-105 transition duration-300">
                    <img src="./assets/img/license/license-03.png" alt="登録支援機関認定証"
                        class="max-h-full max-w-full object-contain">
                </div>
            </div>
        </section>


        <section class="bg-white shadow-lg rounded-xl p-8 mb-16">
            <!-- Tiêu đề -->
            <h2 class="text-4xl font-bold text-blue-700 mb-8 text-center" [innerHTML]="listFormItemNm[30]"></h2>

            <!-- Nội dung -->
            <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                <!-- Thông tin chi tiết -->
                <div class="text-gray-700 leading-relaxed space-y-6">
                    <p [innerHTML]="listFormItemNm[31]">



                    </p>
                    <p class="text-blue-700 cursor-pointer hover:underline" (click)="licenseDetailShow()"
                        [innerHTML]="listFormItemNm[32]">
                </div>

                <!-- Hình ảnh -->
                <div
                    class=" shadow-lg flex justify-center items-center flex flex-col items-center hover:scale-105 transition duration-300">
                    <img src="./assets/img/license/license-04.jpg" alt="登録支援機関認定証"
                        class="max-h-full max-w-full object-contain">
                </div>
            </div>
        </section>

        <!-- Section 4: アクセスマップ -->
        <section class="bg-gradient-to-br from-blue-50 via-white to-gray-50 shadow-lg rounded-xl p-8" id="access-map">
            <h2 class="text-4xl font-bold text-blue-700 mb-6 text-center" [innerHTML]="listFormItemNm[33]"></h2>
            <div class="mb-6">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3238.8292362672205!2d139.76944269999998!3d35.730418!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188dd6e5ead199%3A0x4368e2fb9082513d!2zTmjhuq10IELhuqNuLCDjgJIxMTYtMDAxMyBUb2t5bywgQXJha2F3YSBDaXR5LCBOaXNoaW5pcHBvcmksIDItY2jFjW1l4oiSNTTiiJIyIOW8peaghOODk-ODqyA1MDI!5e0!3m2!1svi!2s!4v1732852139001!5m2!1svi!2s"
                    width="100%" height="400" class="rounded-lg shadow-lg border-0" allowfullscreen="" loading="lazy">
                </iframe>
            </div>
        </section>
    </main>
</div>