import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgFor } from '@angular/common';
import {
    CarouselCaptionComponent,
    CarouselComponent,
    CarouselControlComponent,
    CarouselIndicatorsComponent,
    CarouselInnerComponent,
    CarouselItemComponent,
    ThemeDirective
} from '@coreui/angular';
import { CommonModule } from '@angular/common';
import { scaleFadeIn500ms } from '@vex/animations/scale-fade-in.animation';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { WebServiceService } from 'src/app/services/web-service.service';
import { AccessInfoService } from 'src/app/services/access-info.service';
import { AppFunctionService } from 'src/app/services/app-function.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { SplashScreenService } from 'src/app/services/splash-screen.service';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectCurrentLanguage } from 'src/app/state/language/language.selectors';
import { MatPaginatorIntl, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { PostService } from 'src/app/services/post.service';

@Component({
    selector: 'vex-home',
    standalone: true,
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss',
    animations: [scaleFadeIn500ms],
    imports: [MatPaginatorModule, MatButtonModule, MatIconModule, CommonModule, ThemeDirective, CarouselComponent, CarouselIndicatorsComponent, CarouselInnerComponent, NgFor, CarouselItemComponent, CarouselCaptionComponent, CarouselControlComponent]
})
export class HomeComponent {
    slides: any[] = new Array(3).fill({ id: -1, src: '', title: '', subtitle: '', showCaption: false });
    hover = false;
    listFormItemNm: any = [];
    formItemNmLoaded = false;
    currentLanguage$: Observable<string> | undefined;
    listPosts: any = [];

    constructor(
        private store: Store,
        private loader: LoadingBarService,
        private webService: WebServiceService,
        private readonly accessInfo: AccessInfoService,
        private appFunction: AppFunctionService,
        private paginator: MatPaginatorIntl,
        private router: Router,
        private postService: PostService) {
    }

    ngOnInit(): void {
        // Theo dõi sự thay đổi của language
        this.currentLanguage$ = this.store.select(selectCurrentLanguage);

        this.currentLanguage$.subscribe(language => {
            this.fnGetFormItemNm();
        });
    }

    fnGetFormItemNm() {
        this.formItemNmLoaded = false;
        this.appFunction.getListFormItemNm("home").subscribe({
            next: (listFormItemNm: any) => {
                if (listFormItemNm) {
                    this.listFormItemNm = listFormItemNm;

                    // Tạo lại toàn bộ mảng slides
                    this.slides = [
                        {
                            index: 0,
                            showCaption: false,
                            src: './assets/img/topslide/topslide_01.jpg',
                            title: this.listFormItemNm[1],
                            subtitle: this.listFormItemNm[2]
                        },
                        {
                            index: 1,
                            showCaption: false,
                            src: './assets/img/topslide/topslide_02.jpg',
                            title: this.listFormItemNm[3],
                            subtitle: this.listFormItemNm[4]
                        },
                        {
                            index: 2,
                            showCaption: false,
                            src: './assets/img/topslide/topslide_03.jpg',
                            title: this.listFormItemNm[5],
                            subtitle: this.listFormItemNm[6]
                        }
                    ];

                    // Kích hoạt hiển thị caption slide đầu tiên
                    setTimeout(() => {
                        this.slides[0]['showCaption'] = true;
                    }, 300);

                    this.formItemNmLoaded = true;

                    this.paginator.itemsPerPageLabel = this.listFormItemNm[25];
                    this.paginator.nextPageLabel = this.listFormItemNm[26];
                    this.paginator.previousPageLabel = this.listFormItemNm[27];
                    this.paginator.lastPageLabel = this.listFormItemNm[28];
                    this.paginator.firstPageLabel = this.listFormItemNm[29];
                    this.paginator.changes.next();

                    this.fnGetListNews(true);
                } else {
                    console.error('Không có dữ liệu nào được trả về từ API.');
                }
            },
            error: (error: any) => {
                console.error('Đã xảy ra lỗi khi tải danh sách:', error);
            },
        });
    }

    onItemChange(eventIndex: number): void {
        // Reset tất cả captions
        this.slides.forEach(slide => slide.showCaption = false);

        // Kích hoạt caption cho slide hiện tại
        setTimeout(() => {
            this.slides[eventIndex]['showCaption'] = true;
        }, 500);
    }

    length = 0;
    pageSize = 5;
    pageIndex = 0;
    pageSizeOptions = [5, 10, 15];

    hidePageSize = false;
    showPageSizeOptions = true;
    showFirstLastButtons = true;
    disabled = false;

    pageEvent: PageEvent | undefined;

    handlePageEvent(e: PageEvent) {
        this.pageEvent = e;
        this.length = e.length;
        this.pageSize = e.pageSize;
        this.pageIndex = e.pageIndex;

        this.fnGetListNews(false);
    }

    setPageSizeOptions(setPageSizeOptionsInput: string) {
        if (setPageSizeOptionsInput) {
            this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
        }
    }

    fnGetListNews(isInit: boolean) {
        const request = {
            accessInfo: this.accessInfo.getAll(),
            pageSize: this.pageSize,
            pageIndex: this.pageIndex
        };
        if (isInit) {
            this.webService.callSilentWs('getPosts', request).subscribe({
                next: (data: any) => {
                    if (data && data.listPosts) {
                        this.length = data.listPostCnt;
                        this.listPosts = data.listPosts;
                    }
                },
                error: (error: any) => {
                    console.error('Đã xảy ra lỗi khi tải danh sách:', error);
                },
            });
        } else {
            this.webService.callWs('getPosts', request).subscribe({
                next: (data: any) => {
                    if (data && data.listPosts) {
                        this.length = data.listPostCnt;
                        this.listPosts = data.listPosts;
                    }
                },
                error: (error: any) => {
                    console.error('Đã xảy ra lỗi khi tải danh sách:', error);
                },
            });
        }

    }

    linkToNews() {
        this.router.navigateByUrl("/news");
    }

    postClick(post: any) {
        this.postService.setPost(post);
        this.router.navigateByUrl("/post-detail");
    }
}
